import React from 'react';
import { Box } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import LoginImage from '../images/auto-img/Login.png';
import Footer from '../images/auto-img/FooterImg.png';
import { useGlobalUserData } from '../lib/globalUserData';

export const FISWrapper = ({ children }) => {
  const { step } = useGlobalUserData();
  return (
    <Box container className={styles.wrapper}>
      <img src={LoginImage} style={{ objectFit: 'fill'}}/>
      <Box style={{ width: '416px', padding: '10px', marginTop: '45px' }}>
        <Box className={styles.welcomeHeadingBox}>
          {step === 'fislogin' && (
            <>
              <Box className={styles.welcomeHeading}>
                <Box>Welcome to&nbsp;</Box>
                <Box style={{ color: '#E37617' }}>Dealer Portal!</Box>
              </Box>
              <Box className={styles.welcomeHeadingSmall}>We’ve been expecting you</Box>
            </>
          )
          }
          {step === 'fistroublesignin' && (
            <>
              <Box className={styles.welcomeHeading}>
                <Box>Let us help you</Box>
              </Box>
              <Box className={styles.welcomeHeadingSmall}>That&apos;s okay, it happens!</Box>
            </>
          )
          }
        </Box>
        <Box className={styles.container}>{children}</Box>
        <Box marginTop="10px">
          <img src={Footer}/>
        </Box>
      </Box>
    </Box>
  );
};
