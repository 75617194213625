import { Button, Box, Link } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { useTranslation } from '../contexts/Localization';
import styles from './Enrollment.module.scss';
import { useGlobalUserData } from '../lib/globalUserData';
import axios from 'axios';
import LoaderFis from '../components/Loader/LoaderFIS';
import CustomOTP from './CustomOTP';
import FisAlert from './FisAlert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FISWrapper } from './FISWrapper';
import { getMessage } from '../utils/status';

const Otp = () => {
  const { userData, changeStep, showGlobalAlert, globalAlert, updateUserData } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [fisAlert, setFisAlert] = useState(false);
  const [otpValues, setOtpValues] = useState({
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
  });

  useEffect(() => {
    const inputField = document.querySelector(
      `input[name=field1]`
    );
    inputField?.focus();
    return () => {
      if (
        globalAlert.message === 'Your security code was sent again' ||
        globalAlert.message === 'Please provide a valid One time passcode'
      ) {
        showGlobalAlert({ type: '', message: '' });
      }
    }
  }, [globalAlert]);

  useEffect(() => {
    showGlobalAlert({ type: '', message: '' });
  }, []);

  useEffect(() => {
    const requiredFields = [
      'field1',
      'field2',
      'field3',
      'field4',
      'field5',
      'field6',
    ];
    for (let field of requiredFields) {
      if (!otpValues[field]) {
        break;
      }
    }
  }, [otpValues, error]);

  useEffect(async () => {
    if (checkValid()) {
      setFisAlert(false);
    }
  }, [otpValues]);

  const handleKeyDown = (e) => {
    const fieldname = e.target.name.substr(e.target.name.length - 1);
    const fieldIntIndex = parseInt(fieldname, 10);
    const { maxLength, value } = e.target;
    if (value.length < maxLength && fieldIntIndex <= 6 && e.code === 'Backspace') {
      const prevfield = document.querySelector(
        `input[name=field${fieldIntIndex - 1}]`
      );
      if (prevfield !== null) {
        prevfield.focus();
      }
    }
  };

  const handleOTPChange = (event) => {
    if (event.target.value.match(/^\d*$/)) {
      const { value } = event.target;
      const fieldname = event.target.name.substr(event.target.name.length - 1);
      setOtpValues({ ...otpValues, [event.target.name]: event.target.value });
      const fieldIndex = parseInt(fieldname, 10);
      if (value.length >= event.target.maxLength && fieldIndex < 6) {
        const jumpToNextField = document.querySelector(
          `input[name=field${fieldIndex + 1}]`
        );
        if (jumpToNextField !== null) {
          jumpToNextField.focus();
        }
      }
    }
  };
  const getAlertmessage = (troubleStep) => {
    const messages = {
      troublesigninforgotpass: {
        title: 'Temporary Password Sent',
        body: 'Your temporary password was successfully sent to your email. Please login with your temporary password.',
      },
      troublesigninforgotuser: {
        title: 'Email Sent',
        body: 'Your user id was successfully sent to your email. Please login with user id and your password.',
      },
      troublesigninaccountlocked: {
        title: 'Email Sent',
        body: 'A link has been sent to your email to unlock your account.',
      },
    };
    return messages[troubleStep];
  };

  const submitOtpAfterValidation = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/forgotusername`,
        {
          step: 'username',
          context: 'trouble sign in recover user id',
          data: {
            next: 'true',
            emailOrLoginID: userData.userid,
            email: 'true',
            selectedDeviceId: userData.otpchoice,
            otpPin: Object.values(otpValues).join(''),
            issueType: userData.issueTypes[userData.troubleSigninStep],
          },
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: 'idmauto',
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.status.code === 504) {
            showGlobalAlert({
              type: 'error',
              message:
                'Your One Time Pass-code is invalid. Please check and try again. Your login credentials will be locked after 3 wrong attempts consecutively.',
            });
            setOtpValues({
              field1: '',
              field2: '',
              field3: '',
              field4: '',
              field5: '',
              field6: '',
            });

        } else if (res.data.status.code === 200) {
          const { body } = getAlertmessage(userData.troubleSigninStep);
          showGlobalAlert({ type: 'success', message: body });
          changeStep('fislogin');
        } else if (res.data.status.code >= 205 && res.data.status.code <= 209) {
          showGlobalAlert({ type: 'error', message: getMessage(res.data.status.code).body });
          changeStep('fislogin');
        } else {
          showGlobalAlert({
            type: 'error',
            message: getMessage('defaultCode').body,
          });
        }
      })
      .catch((err) => {
        setOtpValues({
          field1: '',
          field2: '',
          field3: '',
          field4: '',
          field5: '',
          field6: '',
        });
        setLoading(false);
        console.error(err);
        updateUserData({
          ...userData,
          userpassword: '',
          password: '',
        });
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
      });
  };
  const checkValid = () => !Object.values(otpValues).some((otp) => otp === '');

  const submitOtp = () => {
    if (checkValid()) {
      submitOtpAfterValidation();
    } else {
      setFisAlert(true);
    }
  };
  const resendOTP = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/sendOtp`,
        {
          loginName: userData.userid,
          selectedOtpDevices: [
            {
              id: userData.otpchoice,
            },
          ],
        },
        {
          withCredentials: 'include',
          headers: {
            appid: 'idmauto',
            language: apiLanguage,
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setOtpValues({
          field1: '',
          field2: '',
          field3: '',
          field4: '',
          field5: '',
          field6: '',
        });
        if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: 'error',
            message: getMessage(res.data.status.code).body,
          });
        } else {
          showGlobalAlert({
            type: 'success',
            message:
              'Your security code was sent again',
          });
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userpassword: '',
          password: '',
        });
        setOtpValues({
          field1: '',
          field2: '',
          field3: '',
          field4: '',
          field5: '',
          field6: '',
        });
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
      });
  };

  const navigateBack = () => {
    showGlobalAlert({ type: '', message: '' });
    changeStep('fistroubleotpchoice');
  };

  return (
    <>
      <FISWrapper>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            gap: '15px'
          }}
        >
          <div style={{ marginTop: '8px' }}>
            <ArrowBackIcon data-testid="backButtonOTP" onClick={navigateBack} />
          </div>
          <Box className={styles.heading_verification}>
            {t('Additional verification required')}
          </Box>
        </Box>
        <Box className={styles.subHeading}>
          {t('Please enter the 6-digit PIN below')}
        </Box>
        <form
          style={{ width: '90%', marginTop: '20px' }}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {fisAlert && <FisAlert />}
          {globalAlert.message && (
            <FisAlert message={globalAlert.message} type={globalAlert.type} />
          )}
          <CustomOTP
            otpValues={otpValues}
            handleChange={handleOTPChange}
            data={{}}
            txnId={{}}
            setOtpValues={setOtpValues}
            setError={setError}
            handleResend={resendOTP}
            handleKeyDown={handleKeyDown}
          />
          <Box className={styles.btn_wrapper}>
            <Button
              variant="text"
              onClick={submitOtp}
              className={styles.login_button}
              type="submit"
              id="otpContinue"
            >
              {t('Continue')}
            </Button>
          </Box>
        </form>
        <Box className={styles.link_wrapper} id="linkWrapper">
          <Link
            component="button"
            color="primary"
            variant="body2"
            id="loginBtn"
            onClick={() => {
              changeStep('fislogin');
              showGlobalAlert({ type: '', message: '' });
            }}
            style={{ textDecoration: 'underline' }}
          >
            {t('Login with a different user ID')}
          </Link>
        </Box>
      </FISWrapper>
      <LoaderFis open={loading} />
    </>
  );
};

export default Otp;
