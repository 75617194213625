import { Button, Box, Link } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { useTranslation } from '../contexts/Localization';
import styles from './Enrollment.module.scss';
import { useGlobalUserData } from '../lib/globalUserData';
import axios from 'axios';
import LoaderFis from '../components/Loader/LoaderFIS';
import CustomOTP from './CustomOTP';
import FisAlert from './FisAlert';
import { getMessage } from '../utils/status';
import parseJWT from '../utils/parseJWT';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FISWrapper } from './FISWrapper';

const OtpLogin = () => {
  const { userData, changeStep, updateUserData, showGlobalAlert, globalAlert } = useGlobalUserData();
  const { t, apiLanguage } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showFisAlert, setShowFisAlert] = useState(false);

  const [otpValues, setOtpValues] = useState({
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
  });

  useEffect(() => {
    const inputField = document.querySelector(
      `input[name=field1]`
    );
    inputField?.focus();
    return () => {
      if (
        globalAlert.message === 'Your security code was sent again' ||
        globalAlert.message === 'Please provide a valid One time passcode'
      ) {
        showGlobalAlert({ type: '', message: '' });
      }
    }
  }, [ globalAlert ])

  useEffect(() => {
    showGlobalAlert({ type: '', message: '' });    
  }, []);

  useEffect(() => {
    const requiredFields = [
      'field1',
      'field2',
      'field3',
      'field4',
      'field5',
      'field6',
    ];
    for (let field of requiredFields) {
      if (!otpValues[field]) {
        break;
      }
    }
  }, [otpValues, error]);

  const resendOTP = () => {
    console.log(
      "%cResend OTP called!",
      "display: inline-block ; border: 3px solid red ; border-radius: 7px ; " +
      "padding: 10px ; margin: 20px ;"
    );
    console.dir({
      loginName: userData.userid,
      selectedOtpDevices: [
        {
          id: userData.otpchoice,
        },
      ],
    });
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/sendOtp`,
        {
          loginName: userData.userid,
          selectedOtpDevices: [
            {
              id: userData.otpchoice,
            },
          ],
        },
        {
          withCredentials: 'include',
          headers: {
            appid: 'idmauto',
            language: apiLanguage,
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setOtpValues({
          field1: '',
          field2: '',
          field3: '',
          field4: '',
          field5: '',
          field6: '',
        });
        if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: 'error',
            message: getMessage(res.data.status.code).body,
          });
        } else {
          showGlobalAlert({
            type: 'success',
            message:
              'Your security code was sent again',
          });
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userpassword: '',
          password: '',
        });
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
      });
  };

  const submitOtpAfterValidation = () => {
    showGlobalAlert({ type: '', message: '' });
    setShowFisAlert(false);
    const payLoad = {
      step: 'password',
      data: {
        loginName: userData.userid,
        browserFootprint:
          '557ce527b9b389cd30796fc85cb5f8e26f3ef0673cc6737f69cabef5851cbc0e',
        selectedDeviceId: userData.otpchoice,
        otpPin: Object.values(otpValues).join(''),
        password: userData.userpassword,
      },
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/password`,
        payLoad,
        {
          withCredentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: 'idmauto',
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (
          res?.data?.result[0]?.nextStep?.id === 'changepassword'
        ) {
          updateUserData({
            ...userData,
            cookie: res.data.result[0].cookies[0],
          });
          changeStep('migrationchangepassword');
        } else if (
          res?.data?.result[0]?.nextStep?.id === 'policy'
        ) {
          updateUserData({
            ...userData,
            cookie: res.data.result[0].cookies[0],
            policy: {
              pageTitle: res.data.result[0].nextStep.params.pageTitle,
              policyText: res.data.result[0].nextStep.params.policyText,
              signature: res.data.result[0].nextStep.params.signature,
              date: res.data.result[0].nextStep.params.date,
            },
          });
          changeStep('fisterms');
        } else if (
          (res?.data?.result[0]?.nextStep?.id === 'username')
        ) {
          if (res.data.status.code === 302) {
            showGlobalAlert({
              type: 'error',
              message:
                'Your One Time Password is invalid. Check and try again. Your login credentials will be locked after 3 wrong attempts consecutively.',
            });
            setOtpValues({
              field1: '',
              field2: '',
              field3: '',
              field4: '',
              field5: '',
              field6: '',
            });
          } else {
            const { body } = getMessage(res.data.status.code);
            showGlobalAlert({ type: 'error', message: body });
          }
          changeStep('fisloginusername');
        }
        else {
          const { body } = getMessage(res.data.status.code);
          if (res.data.status.code === 504 || res.data.status.code === 302) {
            setLoading(false);
            showGlobalAlert({
              type: 'error',
              message: body,
            });
            setOtpValues({
              field1: '',
              field2: '',
              field3: '',
              field4: '',
              field5: '',
              field6: '',
            });
          } else if (res.data.status.code !== 200) {
            showGlobalAlert({
              type: 'error',
              message: body,
            });
          } else {
            axios
              .post(
                `${process.env.REACT_APP_IDM_API}authentication/idptoken`,
                {
                  cookie: res.data.result[0].cookies[0],
                  replaceToken: true,
                },
                {
                  withCredentials: 'include',
                  // crossDomain: true,
                  headers: {
                    appid: 'idmauto',
                    language: apiLanguage,
                    Accept: 'application/json',
                    'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(async (resp) => {
                const { id_token } = resp.data.result[0];
                const accessToken = id_token.substring(
                  0,
                  id_token.lastIndexOf('.')
                );
                axios
                  .post(
                    `${process.env.REACT_APP_IDM_API}authentication/users/getaccesstoken`,
                    {
                      cookie: res.data.result[0].cookies[0],
                      accessTokenKey: parseJWT(accessToken)?.accessToken,
                    },
                    {
                      withCredentials: 'include',
                      // crossDomain: true,
                      headers: {
                        appid: 'idmauto',
                        language:
                          apiLanguage,
                        Accept: 'application/json',
                        'x-api-key':
                          process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                        'Content-Type': 'application/json',
                      },
                    }
                  )
                  .then(async (respn) => {
                    localStorage.setItem('fisToken', respn.data.accessToken);
                    setLoading(false);
                    window.open(
                      `${process.env.REACT_APP_AUTO_LOGIN_UI_URL}?userType=dealer&accessToken=${respn.data.accessToken}&idToken=${id_token}`,
                      '_self'
                    );
                  })
                  .catch((err) => {
                    console.error(err);
                    updateUserData({
                      ...userData,
                      password: '',
                      userpassword: '',
                    });
                    setOtpValues({
                      field1: '',
                      field2: '',
                      field3: '',
                      field4: '',
                      field5: '',
                      field6: '',
                    });
                    showGlobalAlert({
                      type: 'error',
                      message: getMessage('defaultCode').body,
                    });
                    changeStep('fislogin');
                  });
              })
              .catch((err) => {
                console.error(err);
                setLoading(false);
                setOtpValues({
                  field1: '',
                  field2: '',
                  field3: '',
                  field4: '',
                  field5: '',
                  field6: '',
                });
                updateUserData({
                  ...userData,
                  userpassword: '',
                  password: '',
                });
                showGlobalAlert({
                  type: 'error',
                  message: getMessage('defaultCode').body,
                });
              });
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
        console.log(err);
        updateUserData({
          ...userData,
          userpassword: '',
          password: '',
        });
      });
  };
  const checkValid = () => !Object.values(otpValues).some((otp) => otp === '');
  const submitOtp = () => {
    if (checkValid()) {
      submitOtpAfterValidation();
    } else {
      setShowFisAlert(true);
      // set aletmsg
    }
  };

  useEffect(async () => {
    if (checkValid()) {
      setShowFisAlert(false);
    }
  }, [otpValues]);

  const handleKeyDown = (e) => {
    const fieldname = e.target.name.substr(e.target.name.length - 1);
    const fieldIntIndex = parseInt(fieldname, 10);
    const { maxLength, value } = e.target;
    if (value.length < maxLength && fieldIntIndex <= 6 && e.code === 'Backspace') {
      const prevfield = document.querySelector(
        `input[name=field${fieldIntIndex - 1}]`
      );
      if (prevfield !== null) {
        prevfield.focus();
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.value.match(/^\d*$/)) {
      const { maxLength, value } = e.target;
      const fieldname = e.target.name.substr(e.target.name.length - 1);
      setOtpValues({ ...otpValues, [e.target.name]: e.target.value });
      const fieldIntIndex = parseInt(fieldname, 10);
      if (value.length >= maxLength && fieldIntIndex < 6) {
        const nextfield = document.querySelector(
          `input[name=field${fieldIntIndex + 1}]`
        );
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  const navigateBack = () => {
    showGlobalAlert({ type: '', message: '' });
    changeStep('fisloginotpchoice');
  };

  return (
    <>
      <FISWrapper>
        <Box style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Box
            style={{
              display: 'flex',
              width: '100%',
              gap: '10px'
            }}
          >
            <div style={{marginTop: '8px'}}>
              <ArrowBackIcon
                data-testid="backbuttonOTPLogin"
                onClick={navigateBack}
              />
            </div>
            <Box className={styles.heading_verification}>
              {t('Additional verification required')}
            </Box>
          </Box>
          <Box className={styles.subHeading}>
            {t('Please enter the 6-digit PIN below')}
          </Box>
          {showFisAlert ? <FisAlert /> : ''}
          <form
            style={{ width: '90%', marginTop: '20px' }}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {globalAlert.message && (
              <FisAlert
                message={globalAlert.message}
                type={globalAlert.type}
              />
            )}
            <CustomOTP
              otpValues={otpValues}
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
              data={{}}
              txnId={{}}
              setOtpValues={setOtpValues}
              setError={setError}
              handleResend={resendOTP}
            />
            <Box className={styles.btn_wrapper}>
              <Button
                variant="text"
                type="submit"
                className={styles.login_button}
                onClick={submitOtp}
              >
                {t('Continue')}
              </Button>
            </Box>
          </form>
          <Box className={styles.link_wrapper}>
            <Link
              component="button"
              variant="body2"
              color="primary"
              onClick={() => {
                changeStep('fislogin');
                showGlobalAlert({ type: '', message: '' });
              }}
              style={{ textDecoration: 'underline' }}
            >
              {t('Login with a different user ID')}
            </Link>
          </Box>
        </Box>
      </FISWrapper>
      <LoaderFis open={loading} />
    </>
  );
};

export default OtpLogin;
