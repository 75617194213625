import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  OutlinedInput,
  Button,
  Link,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import styles from "./Enrollment.module.scss";
import { useGlobalUserData } from "../lib/globalUserData";
import LoaderFis from "../components/Loader/LoaderFIS";
import { useTranslation } from "../contexts/Localization";
import { getMessage } from "../utils/status";
import { mask } from "../utils/mask";
import FisAlert from "./FisAlert";
import FisTranslatedAlert from "./FisTranslatedAlert";
import { fetchFIS } from "../utils/fetchFIS";
import parseJWT from "../utils/parseJWT";
import { FISWrapper } from "./FISWrapper";
import { sha256 } from "../utils/sha256";
import { formatDevices } from "../utils/formatDevices";

const Login = () => {
  const { changeStep, userData, updateUserData, globalAlert, showGlobalAlert } =
    useGlobalUserData();
  const { t, apiLanguage } = useTranslation();

  const [formData, setFormData] = useState({ accountType: "empty" });
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localAlert, setLocalAlert] = useState({ type: "", message: "" });
  let tmxDoneRef = useRef(false);

  const showHidePasswordHandler = (e) => {
    e.preventDefault();
    setShowPassword(showPassword ? false : true);
  };

  const gototroubleSignIn = () => {
    changeStep("fistroublesignin");
  };

  const onFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case "userid":
        const maskedUserid = mask(e.target.value);
        updateUserData({
          ...userData,
          userid: e.target.value,
          useridMaskedValue: maskedUserid,
        });
        if (e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please enter user ID",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
      case "userpassword":
      default:
        updateUserData({
          ...userData,
          userpassword: e.target.value,
        });
        if (e.target.value === "") {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "Please enter password",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [e.target.name]: "",
          });
        }
        break;
    }
  };

  useEffect(async () => {
    const preLoadUserId = localStorage.getItem("rememberedUserId") || "";
    const preLoadMaskUserId = mask(preLoadUserId || "");
    console.log("preLoadMaskUserId");
    console.log(preLoadMaskUserId);
    const tmxSessionId = await sha256(crypto.randomUUID());
    sessionStorage.setItem("tmxSessionId", tmxSessionId);
    updateUserData({
      ...userData,
      userid: preLoadUserId,
      globalShowPasswordField: false,
      useridMaskedValue: preLoadMaskUserId,
      rememberMe: preLoadUserId ? true : false,
      userpassword: "",
    });
    setLocalAlert({ type: "", message: "" });
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/threatmatrix/config`,
        {},
        {
          headers: {
            appid: "idmauto",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      ?.then(({ data }) => {
        setLoading(false);
        const tmProfilingUrlSpace = JSON.parse(data.result[0])[
          "tmProfilingUrl "
        ];
        const { tmOrgId, tmProfilingUrl } = JSON.parse(data.result[0]);
        const se = document.createElement("script");
        se.type = "text/javascript";
        se.id = "tmxSessionIdentifier";
        se.src = `${tmProfilingUrl || tmProfilingUrlSpace
          }?org_id=${tmOrgId}&session_id=${tmxSessionId}`;
        document.getElementsByTagName("head")[0].appendChild(se);
        tmxDoneRef.current = true;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    return () => {
      showGlobalAlert({ type: "", message: "" });
      setLocalAlert({ type: "", message: "" });
      updateUserData({ ...userData, globalShowPasswordField: false });
    };
  }, []);

  const proceedWithoutOTPFlow = () => {
    console.log("proceedWithoutOTPFlow");
    showGlobalAlert({ type: "", message: "" });
    const passwordPayload = {
      step: "password",
      data: {
        loginName: userData.userid,
        browserFootprint:
          "557ce527b9b389cd30796fc85cb5f8e26f3ef0673cc6737f69cabef5851cbc0e",
        otpPin: "",
        password: userData.userpassword,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/password`,
        passwordPayload,
        {
          withCredentials: "include",
          headers: {
            appid: "idmauto",
            language: apiLanguage,
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
          },
        }
      )
      .then((resp) => {
        if (
          resp.data.result[0].nextStep &&
          resp.data.result[0].nextStep.id === "changepassword"
        ) {
          updateUserData({
            ...userData,
            cookie: resp.data.result[0].cookies[0],
          });
          setLoading(false);
          changeStep("migrationchangepassword");
        } else if (
          resp.data.result[0].nextStep &&
          resp.data.result[0].nextStep.id === "policy"
        ) {
          updateUserData({
            ...userData,
            cookie: resp.data.result[0].cookies[0],
            policy: {
              pageTitle: resp.data.result[0].nextStep.params.pageTitle,
              policyText: resp.data.result[0].nextStep.params.policyText,
              signature: resp.data.result[0].nextStep.params.signature,
              date: resp.data.result[0].nextStep.params.date,
            },
          });
          setLoading(false);
          changeStep("fisterms");
        } else {
          if (resp.data.status.code === 200) {
            axios
              .post(
                `${process.env.REACT_APP_IDM_API}authentication/idptoken`,
                {
                  cookie: resp.data.result[0].cookies[0],
                  replaceToken: true,
                },
                {
                  withCredentials: "include",
                  // crossDomain: true,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                    appid: "idmauto",
                    language: apiLanguage,
                  },
                }
              )
              .then(async (respo) => {
                const { id_token } = respo.data.result[0];
                const accessToken = id_token.substring(
                  0,
                  id_token.lastIndexOf(".")
                );
                axios
                  .post(
                    `${process.env.REACT_APP_IDM_API}authentication/users/getaccesstoken`,
                    {
                      cookie: resp.data.result[0].cookies[0],
                      accessTokenKey: parseJWT(accessToken)?.accessToken,
                    },
                    {
                      withCredentials: "include",
                      // crossDomain: true,
                      headers: {
                        appid: "idmauto",
                        language: apiLanguage,
                        Accept: "application/json",
                        "x-api-key":
                          process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then(async (respnse) => {
                    localStorage.setItem("fisToken", respnse.data.accessToken);
                    async function handShakeCallFIS() {
                      const handshakeFISData = await fetchFIS(
                        respnse.data.accessToken
                      );
                      console.dir(handshakeFISData);
                      return handshakeFISData;
                    }
                    if (userData.fisGetRequest) {
                      const handshakeResp = await handShakeCallFIS();
                      console.log("handshakeResponse");
                      console.log(handshakeResp);
                      if (!handshakeResp) {
                        // not code comment keeping it for future refernece  window.open(
                        //   `https://idm2-ui-dev.orientalbank.com/?lang=${currentLanguage.locale}&accessToken=${accessToken}`,
                        //   '_self'
                        // );
                        showGlobalAlert({
                          type: "error",
                          message: getMessage("defaultCode").body,
                        });
                        setLoading(false);
                        changeStep("fislogin");
                      } else {
                        window.open(handshakeResp.redirectUrl, "_self");
                      }
                    } else {
                      setLoading(false);
                      window.open(
                        `${process.env.REACT_APP_AUTO_LOGIN_UI_URL}?userType=dealer&accessToken=${respnse.data.accessToken}&idToken=${id_token}`,
                        "_self"
                      );
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                    setLoading(false);
                    updateUserData({
                      ...userData,
                      password: "",
                      userpassword: "",
                    });
                    showGlobalAlert({
                      type: "error",
                      message: getMessage("defaultCode").body,
                    });
                    changeStep("fislogin");
                  });
              })
              .catch((err) => {
                setLoading(false);
                console.error(err);
                showGlobalAlert({
                  type: "error",
                  body: getMessage(err.response.data.status.code).body,
                });
              });
          } else {
            setLoading(false);
            updateUserData({
              ...userData,
              globalShowPasswordField: false,
              userpassword: "",
              password: "",
            });
            setFormData({ ...formData, userpassword: "" });
            if (
              resp.data.status.code === 302 ||
              resp.data.status.code === 504
            ) {
              // you can't enter a wrong otp if you did not enter any otp
              showGlobalAlert({
                type: "error",
                message: getMessage("defaultCode").body,
              });
            } else {
              const { body } = getMessage(resp.data.status.code);
              showGlobalAlert({ type: "error", message: body });
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        showGlobalAlert({
          type: "error",
          message: getMessage("defaultCode").body,
        });
        updateUserData({
          ...userData,
          userpassword: "",
          password: "",
        });
      });
  };

  const userNameNextSteps = (res) => {
    if (res.data.result[0].nextStep?.id === "otpchoice") {
      setLoading(false);
      updateUserData({
        ...userData,
        otpDestinations: res.data.result[0].nextStep.params.devices,
        userid: userData.userid,
        password: userData.userpassword,
        userpassword: userData.userpassword,
        otpDeviceList: formatDevices(
          res.data.result[0].nextStep.params.devices
        ),
      });
      changeStep("fisloginotpchoice");
    } else {
      proceedWithoutOTPFlow();
    }
  };

  const submitUsername = () => {
    setShowPassword(false);
    if (userData.rememberMe) {
      localStorage.setItem("rememberedUserId", userData.userid);
    } else {
      localStorage.removeItem("rememberedUserId", userData.userid);
    }

    if (
      userData.userid &&
      userData.userpassword &&
      !Object.values(formErrors).some((value) => value !== "")
    ) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_IDM_API}authentication/authenticateUser`,
          {
            username: userData.userid,
            password: userData.userpassword,
          },
          {
            withCredentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
              appid: "idmauto",
              language: apiLanguage,
            },
          }
        )
        .then((res) => {
          if (res.data.status.code === 204) {
            showGlobalAlert({ type: "", message: "" });
            changeStep("accountLocked");
          } else if (res.data.status.code === 666) {
            setLoading(false);
            showGlobalAlert({ type: "", message: "" });
            changeStep("passwordExpired");
          } else if (res.data.status.code === 667) {
            setLoading(false);
            showGlobalAlert({
              type: "error",
              message: "Please contact us at 787.620.0101 to update your contact information and proceed."
            });
          } else if (res.data.status.code !== 200) {
            showGlobalAlert({
              type: "error",
              message: getMessage(res.data.status.code).body,
            });
            setLoading(false);
            const updateData = {
              ...userData,
              password: "",
              userpassword: "",
              globalShowPasswordField: false,
            };
            if (!userData.rememberMe) {
              updateData.userid = "";
              updateData.useridMaskedValue = "";
            }
            updateUserData(updateData);
          } else {
            axios
              .post(
                `${process.env.REACT_APP_IDM_API}authentication/username`,
                {
                  step: "username",
                  data: {
                    keyStroke: "",
                    loginName: userData.userid,
                    browserFootprint:
                      "83018eafcb84c4887e3acef509c47b6e09f9e877fa4be94978e93a83a73f6d23",
                    cookie: `tmSessionId=${sessionStorage.getItem(
                      "tmxSessionId"
                    )}`,
                  },
                },
                {
                  withCredentials: "include",
                  headers: {
                    appid: "idmauto",
                    language: apiLanguage,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-api-key": process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
                  },
                }
              )
              .then((res) => {
                if (res.data.status.code !== 200) {
                  const preLoadUserId =
                    localStorage.getItem("rememberedUserId") || "";
                  const preLoadMaskUserId = mask(preLoadUserId || "");
                  setLoading(false);
                  updateUserData({
                    ...userData,
                    userid: preLoadUserId,
                    useridMaskedValue: preLoadMaskUserId,
                    rememberMe: preLoadUserId ? true : false,
                    password: "",
                    userpassword: "",
                    globalShowPasswordField: false,
                  });
                  showGlobalAlert({
                    type: "error",
                    message: getMessage(res.data.status.code).body,
                  });
                } else {
                  updateUserData({
                    ...userData,
                    password: userData.userpassword,
                    userpassword: userData.userpassword,
                  });
                  userNameNextSteps(res);
                }
              })
              .catch((err) => {
                const updateData = {
                  ...userData,
                  password: "",
                  userpassword: "",
                  globalShowPasswordField: false,
                };
                if (!userData.rememberMe) {
                  updateData.userid = "";
                  updateData.useridMaskedValue = "";
                }
                updateUserData(updateData);
                setLoading(false);
                console.error(err);
                showGlobalAlert({
                  type: "error",
                  message: getMessage("defaultCode").body,
                });
              });
          }
        })
        .catch((erro) => {
          updateUserData({
            ...userData,
            password: "",
            userpassword: "",
            globalShowPasswordField: false,
          });
          setLoading(false);
        });
    } else {
      if (!userData.userid) {
        setFormErrors({
          ...formErrors,
          userid: userData.userid ? "" : "Please enter user ID",
        });
      } else {
        setFormErrors({
          ...formErrors,
          userpassword: userData.userpassword ? "" : "Please enter password",
        });
      }
    }
  };

  const rememberToggle = () => {
    updateUserData({ ...userData, rememberMe: !userData.rememberMe });
  };

  return (
    <>
      <FISWrapper>
        <div
          style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
        >
          <Box data-testid="heading" className={styles.heading}>
            {t("Sign in")}
          </Box>
        </div>
        {/* <Box className={styles.subHeading}>
            Let's start by verifying who you are.
          </Box> */}
        <form
          style={{ width: "100%", marginTop: "20px" }}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
          id={userData.globalShowPasswordField ? "passwordpage" : "useridpage"}
        >
          {globalAlert.message && (
            <FisAlert message={globalAlert.message} type={globalAlert.type} />
          )}
          {localAlert.message && (
            <FisTranslatedAlert
              message={localAlert.message}
              type={globalAlert.type}
            />
          )}
          <Box className={styles.boxMarginTop}>
            <InputLabel className={styles.input_label}>
              {t("User ID")}
            </InputLabel>
            <FormControl
              style={{
                width: "100%",
                backgroundColor:
                  userData.globalShowPasswordField || loading
                    ? "#f2edf2"
                    : "",
              }}
              error={formErrors && formErrors.userid}
              variant="outlined"
            >
              <OutlinedInput
                id="userid"
                name="userid"
                // label="User ID"
                error={formErrors && formErrors.userid}
                autoFocus
                onChange={onFormData}
                placeholder={t("Enter user ID")}
                fullWidth
                disabled={
                  userData.globalShowPasswordField || loading
                }
                inputProps={{ maxLength: 50 }}
                autoComplete="off"
                value={userData.userid}
                className={styles.passwordInput}
              />
            </FormControl>
            {formErrors.userid && (
              <Box className={styles.inlineAlert}>
                <InfoOutlinedIcon fontSize="small" />
                {t(formErrors.userid)}
              </Box>
            )}
          </Box>
          <Box className={styles.boxMarginTop}>
            <InputLabel className={styles.input_label}>
              {t("Password")}
            </InputLabel>
            <FormControl
              style={{ width: "100%" }}
              error={formErrors && formErrors.userpassword}
              variant="outlined"
            // onBlur={() => {
            //   setShowPassword(false);
            //   console.log('hjkhjkhhhuyyiyuy');
            // }}
            >
              <OutlinedInput
                id="userpassword"
                name="userpassword"
                onChange={onFormData}
                placeholder={t("Enter password")}
                fullWidth
                inputProps={{ maxLength: 50 }}
                autoComplete="off"
                type={!showPassword ? "password" : "text"}
                value={userData.userpassword}
                style={{ borderRight: "none" }}
                className={styles.passwordInput}
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{
                      marginLeft: "0",
                      marginRight: "-7px",
                      maxWidth: "35px",
                    }}
                    tabIndex="0"
                  // onBlur={pwdIconBlur}
                  >
                    <IconButton
                      onClick={showHidePasswordHandler}
                      tabIndex="0"
                      data-testid="showHidePasswordHandler"
                    >
                      {showPassword === true ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {formErrors.userpassword && (
              <Box className={styles.inlineAlert}>
                <InfoOutlinedIcon fontSize="small" />
                {t(formErrors.userpassword)}
              </Box>
            )}
          </Box>
          <Box
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <input
                type="checkbox"
                id="rememberme"
                data-testid="rememberme"
                name="rememberme"
                onChange={rememberToggle}
                checked={userData.rememberMe}
                className={userData.rememberMe ? "checked" : "unchecked"}
                style={{ transform: "scale(1.5)" }}
              />{" "}
              <label for="rememberme" style={{ fontSize: "14px" }}>
                {t("Remember me")}
              </label>
            </Box>
            <Link
              component="button"
              type="button"
              variant="body2"
              color="primary"
              onClick={gototroubleSignIn}
              style={{ textDecoration: "underline" }}
            >
              {t("Trouble signing in?")}
            </Link>
          </Box>
          <Box className={styles.btn_wrapper}>
            <Button
              variant="text"
              type="submit"
              className={styles.login_button}
              onClick={submitUsername}
            >
              {t("Sign in")}
            </Button>
          </Box>
        </form>
        <Box className={styles.privacyPolicy}>
          We promise to keep your personal information private and secure. To
          learn more, please see our{" "}
          <Link
            style={{ textDecoration: "underline" }}
            component="button"
            type="button"
            variant="body2"
            color="primary"
            // onClick={}
            href="/"
          >
            privacy policy
          </Link>
          .
        </Box>
      </FISWrapper>
      <LoaderFis open={loading} />
    </>
  );
};

export default Login;
