import React from 'react';
import { Box, Button } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import { useTranslation } from '../contexts/Localization';
import { useGlobalUserData } from '../lib/globalUserData';
import { FISWrapper } from './FISWrapper';
import AccountLockedError from '../images/auto-img/AccountLockedError.png';

const AccountLocked = () => {
  const { changeStep } = useGlobalUserData();
  const { t } = useTranslation();

  return (
    <FISWrapper>
      <Box>
        <img src={AccountLockedError} />
      </Box>
      <Box className={styles.accountUnlocked}>
        Account locked
      </Box>
      <Box className={styles.accountUnlockedMessage}>
        Your account has been locked due to multiple incorrect attempts. Click trouble signing in to reset your access information.
      </Box>
      <Box className={styles.btn_wrapper} style={{ width: '100%' }}>
        <Button
          variant="text"
          onClick={() => changeStep('fistroublesignin')}
          className={styles.login_button}
          type="button"
        >
          {t('Trouble signing in')}
        </Button>
      </Box>
    </FISWrapper>
  );
};

export default AccountLocked;
