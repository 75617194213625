import React from "react";
import { Grid, Select, MenuItem, Box } from "@material-ui/core";

import { topHeaderStyles } from "./TopHeaderStyles";
import OrientalImage from "../../images/oriental-bank.png";
import GlobeIcon from "../../images/auto-img/Globe-Icon.svg";
import { useGlobalUserData } from "../../lib/globalUserData";
import { useTranslation } from "../../contexts/Localization";

const useStyles = topHeaderStyles();

export default function TopHeader() {
  const styledClasses = useStyles();
  const { changeStep } = useGlobalUserData();

  const goHome = () => {
    changeStep("fislogin");
  };

  const { currentLanguage, setLanguage } = useTranslation();
  // may be needed later const [languageString, setLanguageString] = useState('ENG');
  // also may be needed later {/* {t(' %thisYear% orientalbank.com. All Rights Reserved. FDIC Member.', { thisYear: `${new Date().getFullYear()}` })} */}
  const codeInfo = {
    ES: { locale: "es-ES", language: "Español", code: "es-ES" },
    EN: { locale: "en-US", language: "English", code: "en" },
  };

  const sendLanguage = (e) => {
    setLanguage(codeInfo[e.target.value]);
  };

  return (
    <>
      <Grid container className={styledClasses.top_header}>
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <img
            src={OrientalImage}
            className={styledClasses.oriental}
            tabIndex="0"
            alt="Oriental-Logo"
            aria-label="Oriental Logo"
            onClick={goHome}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className={styledClasses.right_mobile_header}>
          <img src={GlobeIcon} />
          <Box className={styledClasses.translationToggle}>
            <Select
              data-testid="languageSelect"
              role="listbox"
              fullWidth
              onChange={sendLanguage}
              value={currentLanguage.code === "en" ? "EN" : "ES"}
            >
              {["EN", "ES"].map((lang) => (
                <MenuItem
                  // style={{ textDecoration: 'underline' }}
                  role="option"
                  key={lang}
                  value={lang}
                  id={lang}
                  data-testid={lang}
                  style={{ fontSize: "12px" }}
                >
                  {lang}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </div>
      </Grid>
    </>
  );
}
