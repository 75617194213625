const status = {
  301: {
    title: "Invalid Credentials",
    body: "The username and password combination is not valid, please try again. For your safety, after 3 consecutive incorrect attempts, your access will be locked.",
  },
  302: {
    title: "Invalid Credentials",
    body: "Your One Time Password is invalid. Check and try again. Your login credentials will be locked after 3 wrong attempts consecutively.",
  },
  102: {
    title: "Account locked",
    body: "Your account has been temporary locked due to multiple failed attempts. Please try again after 60 minutes.",
  },
  504: {
    title: "Invalid One-Time Password",
    body: "Your One Time Pass-code is invalid. Please check and try again. Your login credentials will be locked after 3 wrong attempts consecutively.",
  },
  501: {
    title: "Invalid Email",
    body: "We could not locate an account with the information provided. Please try again or contact customer support.",
  },
  503: {
    title: "Invalid Email",
    body: "We could not locate an account with the information provided. Please try again or contact customer support.",
  },
  103: {
    title: "Invalid Credentials",
    body: "The username and password combination is not valid, please try again. For your safety, after 3 consecutive incorrect attempts, your access will be locked.",
  },
  901: {
    title: "Invalid Credentials",
    body: "The username and password combination is not valid, please try again. For your safety, after 3 consecutive incorrect attempts, your access will be locked.",
  },
  204: {
    title: "Account locked",
    body: "Your account has been locked due to multiple incorrect attempts. Click Trouble Signing In to reset your access information.",
  },
  201: {
    title: "Account locked",
    body: "Your account has been locked. Please contact CSR.",
  },
  750: {
    title: "Invalid link",
    body: "Your link is invalid or expired. Please try again.",
  },
  751: {
    title: "Invalid link",
    body: "Your link is invalid or expired. Please try again.",
  },
  402: {
    title: "Invalid Credentials",
    body: "Your previous password does not match with our records. Please review and try again.",
  },
  403: {
    title: "Invalid Password",
    body: "New password should not match last 10 passwords",
  },
  205: {
    title: "Account locked",
    body: "Your account has been locked. Contact us on 787.620.0101 to unlock and reset your credentials.",
  },
  206: {
    title: "Account locked",
    body: "Your account is locked due to multiple one-time passcode requests. Please try after 60 minutes.",
  },
  207: {
    title: "Account locked",
    body: "Your account is locked due to multiple one-time passcode requests. Please try after 24 hours.",
  },
  208: {
    title: "Account locked",
    body: "Your account has been temporary locked due to multiple failed attempts. Please try again after 60 minutes.",
  },
  209: {
    title: "Account locked",
    body: "Your account is temporary locked due to multiple failed attempts. Please try after 24 hours.",
  },
  194: {
    title: "Account locked",
    body: "Your account has been temporary locked due to multiple failed attempts. Please try again after 60 minutes.",
  },
  defaultCode: {
    title: "Error occurred",
    body: "We are unable to process your request. Please try again later.",
  },
};

const getMessage = (code) => {
  return status[code || "defaultCode"] || status.defaultCode;
};

export { status, getMessage };
