import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Select, MenuItem, Box, Link } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import LoaderFis from '../components/Loader/LoaderFIS';
import { useGlobalUserData } from '../lib/globalUserData';
import { useTranslation } from '../contexts/Localization';
import styles from './Enrollment.module.scss';
import selectStyles from './CustomSelect.module.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'; 
import { FISWrapper } from './FISWrapper';
import { getMessage } from '../utils/status';
import FisAlert from './FisAlert';

const OtpChoice = () => {
  const { t, apiLanguage } = useTranslation();
  const { changeStep, userData, updateUserData, showGlobalAlert, globalAlert } =
    useGlobalUserData();
  const [formData, setFormData] = useState({ otpchoice: 'empty' });
  const [selectedDevice, setSelectedDevice] = useState({
    label: '',
    deviceInfo: [],
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    showGlobalAlert({ type: '', message: '' });
    setFormData({
      ...formData,
      ...userData,
      otpchoice: userData?.otpDeviceList[0]?.label,
    });
    const selectDev = userData.otpDeviceList[0];
    setSelectedDevice(selectDev);
  }, []);

  const generateSendOtpText = (label) => {
    switch (label) {
      case 'MOBILE':
      case 'SMS':
        return (<>{t('Send SMS')}</>);
      case 'EMAIL':
        return (<>{t('Send code to email')}</>);
      case 'VOICE':
      default:
        return (<>{t('Send voice call')}</>);
    }
  };

  const onFormData = (e) => {
    setSelectedDevice(
      userData.otpDeviceList.find((destn) => destn.label === e.target.value)
    );
    setFormErrors({
      ...formErrors,
      captchaerror: '',
      [e.target.name]: '',
    });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitOtpChoice = (otpchoice) => {
    console.log(userData.token);
    setLoading(true);
    updateUserData({ ...userData, otpchoice });
    axios
      .post(
        `${process.env.REACT_APP_IDM_API}authentication/forgotusername`,
        {
          step: 'username',
          context: 'trouble sign in recover user id',
          data: {
            next: 'true',
            emailOrLoginID: userData.userid,
            email: 'true',
            selectedDeviceId: otpchoice,
            issueType: userData.issueTypes[userData.troubleSigninStep],
          },
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY_AUTHENTICATION,
            appid: 'idmauto',
            language: apiLanguage,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        updateUserData({ ...userData, otpchoice });
        if (res.data.status.code !== 200) {
          showGlobalAlert({
            type: 'error',
            message: getMessage(res.data.status.code).body,
          });
        } else {
          changeStep('fisotp');
        }
      })
      .catch((err) => {
        updateUserData({
          ...userData,
          userpassword: '',
          password: '',
        });
        setLoading(false);
        showGlobalAlert({
          type: 'error',
          message: getMessage('defaultCode').body,
        });
        console.error(err);
      });
  };

  const navigateBack = () => {
    showGlobalAlert({ type: '', message: '' });
    changeStep('fistroublesignin');
  }

  return (
    <>
      <FISWrapper>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            gap: '15px'
          }}
        >
          <div style={{marginTop: '8px'}}>
            <ArrowBackIcon onClick={navigateBack} data-testid="backButtonOTPChoice" />
          </div>
          <Box className={styles.heading_verification} id="stylesHeading">
            {t('Additional verification required')}
          </Box>
        </Box>
        <Box>
          {globalAlert.message && (
            <FisAlert message={globalAlert.message} type={globalAlert.type} />
          )}
        </Box>
        <Box className={styles.subHeading} id="stylesSubHeading">
          {t(
            'We noticed this is the first time you logged in on this device. Please verify your device to continue'
          )}
        </Box>
        <form style={{ width: '100%', margin: '20px 0', height: '65px' }} id="otpchoiceForm">
          <Box
            className={styles.boxMarginTop}
            style={{ height: '40px' }}
            id="boxMarginTop"
          >
            <Box className={styles.otpchoiceinputlabel}>Deliver my code to:</Box>
            <Select
              id="otpchoice"
              className={styles.customInput}
              name="otpchoice"
              variant="outlined"
              role="listbox"
              value={formData.otpchoice || ''}
              onChange={onFormData}
              fullWidth
              IconComponent={KeyboardArrowDownIcon}
              classes={{
                icon: 'select-icon',
                select:
                  formData.otpchoice === ''
                    ? selectStyles.select_inputs_placeholder
                    : selectStyles.select_style,
              }}
            >
              <MenuItem role="option" value="empty" id="passCodeDestination">
                {t('Select a destination for your One Time Passcode')}
              </MenuItem>
              {userData.otpDeviceList.map(({ label }) => (
                <MenuItem
                  className={selectStyles.otp_menuitem_style}
                  key={label}
                  value={label}
                  role="option"
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </form>
        <Box className={styles.trouble_otpchoice_btn_wrapper}>
          {selectedDevice?.deviceInfo.length > 0 &&
            selectedDevice?.deviceInfo.map((sendMode) => (
              <>
                <Button
                  type="button"
                  variant="outlined"
                  className={styles.login_button}
                  key={sendMode.value}
                  color="primary"
                  fullWidth
                  onClick={() => submitOtpChoice(sendMode.value)}
                >
                  {generateSendOtpText(sendMode.groupLabel)}
                </Button>
              </>
            ))}
        </Box>
        <Box className={styles.link_wrapper} id="linkWrapperBox">
          <Link
            id="loginNew"
            component="button"
            color="primary"
            variant="body2"
            onClick={() => {
              changeStep('fislogin');
              showGlobalAlert({ type: '', message: '' });
            }}
            style={{ textDecoration: 'underline' }}
          >
            {t('Login with a different user ID')}
          </Link>
        </Box>
      </FISWrapper>
      <LoaderFis open={loading} />
    </>
  );
};

export default OtpChoice;
