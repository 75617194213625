export const isValidEmail = (value) => {
  if (
    value.match(
      /^([a-zA-Z0-9_\.\-]+)@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/
    )
  ) {
    const [emailFirstPart] = value.split('@');
    return (
      emailFirstPart.charAt(0) !== '.' &&
      emailFirstPart.charAt(0) !== '-' &&
      emailFirstPart.charAt(emailFirstPart.length - 1) !== '-' &&
      emailFirstPart.charAt(emailFirstPart.length - 1) !== '.' &&
      !value.match(/[.\-\_]{2,}/)
    );
  } else {
    return false;
  }
};
