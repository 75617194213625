import { Requirement } from './Requirement';
import React, { useEffect } from 'react';

export const Requirements = ({ value, requirements, onValidChange }) => {
  // maybe needed const validChangeCb = onValidChange;
  // const validChangeCb = useCallback(onValidChange, []);
  // console.log('value');
  // console.log(value);

  useEffect(() => {
    onValidChange(requirements.every((r) => r.validator(value)));
  }, [value, requirements, onValidChange]);

  return requirements.map((r) => (
    <Requirement
      key={r.text}
      value={value}
      requirement={r}
      onValidChange={onValidChange}
    />
  ));
};
