import React from 'react';
import { Box, Button } from '@material-ui/core';
import styles from './Enrollment.module.scss';
import { useTranslation } from '../contexts/Localization';
import { useGlobalUserData } from '../lib/globalUserData';
import { FISWrapper } from './FISWrapper';
import AccountLockedError from '../images/auto-img/AccountLockedError.png';

const PasswordExpired = () => {
  const { changeStep, userData, updateUserData } = useGlobalUserData();
  const { t } = useTranslation();

  return (
    <FISWrapper>
      <Box>
        <img src={AccountLockedError} />
      </Box>
      <Box className={styles.accountUnlocked}>
        Password expired
      </Box>
      <Box className={styles.accountUnlockedMessage}>
        Your password has expired. Please reset your password to login again.
      </Box>
      <Box className={styles.btn_wrapper} style={{ width: '100%' }}>
        <Button
          variant="text"
          onClick={() => {
            changeStep('migrationchangepassword');
            updateUserData({
              ...userData,
              passwordExpired: true,
            });
          }}
          className={styles.login_button}
          type="button"
        >
          {t('Reset password')}
        </Button>
      </Box>
    </FISWrapper>
  );
};

export default PasswordExpired;
